<template>
  <div id="program">
    <app-navbar></app-navbar>
    <div class="redWrap">
      <img v-parallax="0.2" class="img-fluid" src="@/assets/redWrap.png" alt="redWrap">
    </div>
    <div class="redBalloneHalf">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/redBalloneHalf.png" alt="redBalloneHalf">
    </div>
    <div class="container program-block">
      <h1 class="text-center">{{ "Program.title" | staticTranslation }}</h1>
<!--      <h4 class="text-center pb-4">{{ "Program.video" | staticTranslation }}</h4>-->

<!--      <div class="col-lg-8 offset-lg-2">-->
<!--        <div class="frameContainer box-shadow-3d">-->
<!--          <iframe loading="lazy" class="responsive-iframe" src="https://www.youtube.com/embed/QMuFBdIWjI8"-->
<!--                  title="YouTube video player"-->
<!--                  frameborder="0"-->
<!--                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                  allowfullscreen></iframe>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="pb-1">-->
<!--        <h2 class="text-center">Doporučujeme</h2>-->
<!--        <div class="row pb-4">-->
<!--          <div class="col-lg-3">-->
<!--            <div class="new-container">-->
<!--              <a href="/program/interpret/228">-->
<!--                <h5>Omar Sosa</h5>-->
<!--                <img class="img-fluid box-shadow-3d" src="@/assets/recommend/2023/Omar_Sosa.jpeg" alt="Omar_Sosa.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-lg-3">-->
<!--            <div class="new-container">-->
<!--              <a href="/program/interpret/226">-->
<!--                <h5>Dubioza Kolektiv</h5>-->
<!--                <img class="img-fluid box-shadow-3d" src="@/assets/recommend/2023/Dubioza_kolektiv.jpeg"-->
<!--                     alt="Dubioza Kolektiv">-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-lg-3">-->
<!--            <div class="new-container">-->
<!--              <a href="/program/interpret/225">-->
<!--                <h5>Puuluup</h5>-->
<!--                <img class="img-fluid box-shadow-3d" src="@/assets/recommend/2023/Puuluup.jpeg" alt="Omar_Sosa.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-lg-3">-->
<!--            <div class="new-container">-->
<!--              <a href="/program/interpret/262">-->
<!--                <h5>Vendula Příhodová</h5>-->
<!--                <img class="img-fluid box-shadow-3d" src="@/assets/recommend/2023/vendula_prihodova.jpg"-->
<!--                     alt="Vendula Příhodová">-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <h4 class="text-center pb-lg-5 pt-5">{{ "Program.textEntrance" | staticTranslation }}</h4>

      <div class="col-lg-12">
        <div class="padding-stages">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-5">
                <div class="form-group">
                  <label>{{ "Program.stage" | staticTranslation }}</label>
                  <select v-on:change="filter" class="form-control" v-model="selectedStageId">
                    <option :value="null">{{ "Program.all" | staticTranslation }}</option>
                    <option v-bind:key="stage.id" v-bind:value="stage.id" v-for="stage in stages">
                      {{ stage.name | translation }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="form-group">
                  <label>{{ "Program.genre" | staticTranslation }}</label>
                  <select v-on:change="filter" class="form-control" v-model="selectedGenreId">
                    <option :value="null">{{ "Program.all" | staticTranslation }}</option>
                    <option v-bind:key="genre.id" v-bind:value="genre.id" v-for="genre in genres">
                      {{ genre.name | translation }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion">
          <div class="card" v-for="day in filteredPeriod.days" v-bind:key="$options.filters.dateId(day.date)">
            <div class="card-header" v-bind:id="'heading' + $options.filters.dateId(day.date)">
              <h5 class="mb-0">
                <button class="btn btn-link" data-toggle="collapse"
                        v-bind:data-target="'#collapse' + $options.filters.dateId(day.date)"
                        v-bind:aria-controls="'collapse' + $options.filters.dateId(day.date)" aria-expanded="true"><p>
                  ▾</p>
                  <p class="date-day"> {{ day.date | dateWithName }} </p></button>
              </h5>
            </div>

            <div v-bind:id="'collapse' + $options.filters.dateId(day.date)" class="collapse show"
                 v-bind:aria-labelledby="'heading' + $options.filters.dateId(day.date)"
                 data-parent="#accordion">
              <div class="card-body pl-lg-4">
                <div class="pb-lg-4" v-for="stage in day.stages" v-bind:key="stage.name | translation">
                  <h4>{{ stage.name | translation }}</h4>
                  <p v-if="$options.filters.translation(stage.note) != null" style="font-size: small">
                    ({{ stage.note | translation }})</p>
                  <div class="pb-lg-2" v-for="event in stage.events" v-bind:key="event.id">
                    <p class="size-time">{{ event.timeFrom | time }}</p>
                    <p v-if="event.timeTo" class="size-time">-</p>
                    <p class="size-time">{{ event.timeTo | time }}</p>
                    <div v-for="(interpret, index) in event.interprets" v-bind:key="interpret.id"
                         style="display: inline-block">
                      <a v-bind:href="'/program/interpret/' + interpret.id"
                         class="pl-lg-3 notButton">{{ interpret.name | translation }}</a>
                      <span v-if="index < event.interprets.length - 1"><b>&</b></span>
                    </div>
                    <div class="d-inline-block"
                         v-for="(genre, index) in getUniqueValues(event.interprets.flatMap(it => it.genres))"
                         v-bind:key="genre.id">
                      <p class="size-genre" v-if="index !== 0">-</p>
                      <p class="size-genre">{{ genre.name | translation }}</p>
                      <p :style="'background-color: ' + genre.color" class="cyrcle"></p>
                    </div>
                    <p v-if="event.ticketLink" class="pl-lg-2 size-ticket">-></p>
                    <a v-if="event.ticketLink && event.ticketLink !== 'free'" class="ml-lg-4 mt-lg-4 size-ticket"
                       v-bind:href="event.ticketLink">{{ "Navigation.entrance.tickets" | staticTranslation }}</a>
                    <a v-if="event.ticketLink === 'free'"
                       class="ml-lg-4 mt-lg-4 size-ticket">{{ "Navigation.entrance.free" | staticTranslation }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import axios from "@/api/axiom-instance";
import footer from "@/components/base/footer";

export default {
  name: "Program",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  },
  data() {
    return {
      period: {},
      filteredPeriod: {},
      genres: [],
      stages: [],
      selectedStageId: null,
      selectedGenreId: null
    }
  },
  created() {
    axios.get("/period/actual").then((success) => {
      this.period = success.data;
      this.filteredPeriod = this.period
    }).catch((failed) => {
      console.log(failed)
    })
    axios.get("/stage/list/actual").then((success) => {
      this.stages = success.data;
    }).catch((failed) => {
      console.log(failed)
    })
    axios.get("/genre/list/actual").then((success) => {
      this.genres = success.data;
    }).catch((failed) => {
      console.log(failed)
    })
  },
  methods: {
    getUniqueValues(array) {
      const result = []
      const resultNames = []
      array.forEach(it => {
        if (!resultNames.includes(it.name.cs)) {
          resultNames.push(it.name.cs)
          result.push(it)
        }
      })
      return result
    },
    filter: function () {
      this.filteredPeriod = JSON.parse(JSON.stringify(this.period))
      this.filteredPeriod.days = this.filteredPeriod.days.filter(day => {
        day.stages = day.stages.filter(stage => {
          stage.events = stage.events.filter(event => {
            return !this.selectedGenreId || event.interprets.some(interpret => interpret.genres.map(it => it.id).includes(this.selectedGenreId))
          })
          return (!this.selectedStageId || stage.id === this.selectedStageId) && stage.events.length > 0
        })
        return day.stages.length > 0
      })
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 2.6em;
  padding-top: 3em;
  padding-bottom: 1.7em;
  font-family: "Transcript Pro";
}

h2 {
  font-size: 2.1em;
  padding-bottom: .5em;
  color: black;
  font-family: "Transcript Pro";
}

h4 {
  color: black;
  font-size: 1.4em;
  /*display: inline-block;  při spuštění programu nutné přidat*/
  font-family: "Transcript Pro";
}

.new-container>h5 {
  position: absolute;
  background-color: #eae7d6;
  position: absolute;
  padding: 3px 6px 3px 6px;
  bottom: 0;
  margin-bottom: 0;
}

p {
  color: black;
  font-size: 1.4em;
  font-family: "Transcript Pro";
  margin-bottom: 0;
  padding-right: .8em;
  display: inline-block;
}

.size-ticket {
  padding-right: .5em;
  font-size: 1em;
}

.size-ticket:hover {
  font-size: 1em;
}

.size-time {
  font-size: 1.1em;
  padding-bottom: 10px;
}

.size-genre {
  font-size: 1em;
}

.date-day {
  font-size: 1.3em;
}

.new-container > a:hover {
  color: black;
  padding: 0;
  border: none;
}

.new-container > a {
  color: black;
  padding: 0;
  border: none;
}

.new-container {
  position: relative;
}

.box-shadow-3d {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.notButton {
  border: none;
  padding: 0px 1em 0px 0px;
  text-decoration: underline;
}

.notButton:hover {
  color: black;
  background-color: #f3ba53;

}

.btn:focus {
  box-shadow: none;
}

a {
  color: black;
  border: solid 1px black;
  padding: 8px;
  font-size: 1.3em;
  font-family: "Transcript Pro";
}

a:hover {
  color: #f3ba53;
  background-color: black;
  text-decoration: none;
  font-size: 1.3em;
  font-family: "Transcript Pro";
}

.col-lg-5 {
  padding-left: 2px;
}

.card {
  border: 2px solid black;
  border-radius: 0;
  margin-bottom: 1em;
  background-color: #f3ba53;
}

.card-header {
  background-color: #eae7d6;
  border-bottom: 1px solid black;
}

.redBalloneHalf {
  position: absolute;
  text-align: end;
  right: 0;
  width: 10%;
  top: 80%;

}

.redWrap {
  width: 8%;
  position: absolute;
  top: 20%;
  left: 8%;
}

.card-body {
  background-color: #f3ba53;
}

#program {
  background-color: #f3ba53;
  min-height: 100vh;
}

.padding-stages {
  padding-bottom: 3rem;
}

label {
  color: black;
  font-family: "Transcript Pro";
}

.form-control {
  background-color: black;
  color: #f3ba53;
  font-family: "Transcript Pro";
  border-color: black;
  border-radius: 0;
}

.cyrcle {
  clip-path: circle(50% at 50% 50%);
  padding: 6px;
  margin-right: .8em;
}

.program-block {
  padding-bottom: 10em;
  min-height: 120vh;
}

.frameContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
